<template>
    <div class="testPage" ref="testPage" :style="{overflow:showGradeList?'hidden':'auto'}">
        <!--        <div class="task_content">-->
        <van-sticky :style="{height:`${topH}px !important`}">
            <div class="grade_select" ref="gradeSelect">
                <div class="grade_text" :class="{active:showGradeList}" @click="showGradeList = !showGradeList">
                    {{gradeSelected}}
                </div>

            </div>
            <div class="subject_select" ref="subjectSelect">
                <van-tabs v-model="subjectId" @click="subjectChange(subjectId)">
                    <van-tab :title="subject.name" :name="subject.subjectId" v-for="(subject,index) in subjectList"
                             :key="index"></van-tab>
                </van-tabs>
            </div>
        </van-sticky>

        <!--        </div>-->
       <van-sticky :offset-top="topH" @change="fixedChange" >
            <div class="task_type" ref="taskType">
                <van-tabs v-model="typeIndex" @click="onTabs">
                    <van-tab :title="type.title" v-for="(type,index) in typeList" :key="index">
                        <div class="task_list"
                             :style="{minHeight:`${listH}px`,maxHeight:`${listH}px`, height: '100%'}"
                        >
                            <van-pull-refresh
                                :disabled="isFixed"
                                success-text="刷新成功"
                                v-model="type.refreshing"
                                @refresh="onRefresh()">
                                <van-list
                                    style="height: 100%;"
                                    v-model="type.loading"
                                    :finished="type.finished"
                                    finished-text="没有更多了"
                                    @load="getTestList()"
                                >
                                    <!--<van-cell v-for="item in type.data" :key="item.id" :title="item.examinationName" />-->
                                    <div class="list_item" @click="cardItemClick(item)" v-for="(item,index) in type.data" :key="index">
                                        <div class="item_top">
                                            <div class="subject_icon"
                                                 :style="{background:$Z.getSubjectColor($Z.getSubjectTitleById(item.subjectids[0],subjectList))}">
                                                {{$Z.getSubjectTitleById(item.subjectids[0],subjectList)}}
                                            </div>
                                            <div class="task_info">
                                                <div class="task_title">{{item.examinationName}}</div>
                                                <div class="task_info_box">
                                                    <div class="info_item">满分：{{item.fullMarkScore}}</div>
                                                    <div class="info_item result" v-if="item.correctable === 'corrected'">
                                                        得分：{{item.studentTotalScore}}
                                                    </div>
                                                    <div class="info_item "></div>
                                                </div>
                                            </div>
                                            <div class="item_arrow">
                                                <img src="../../../public/iconImg/icon_arrow.png" alt="">
                                            </div>
                                        </div>
                                        <div class="item_bottom">
                                            <div class="item_grade">{{$Z.getGradeTitleById(item.gradeid,gradeInfo)}}</div>
                                            <div class="item_date">{{item.openTime}}</div>
                                            <div class="item_btn">
                                                <div class="card_btn undo" v-if="item.status === -1">作业过期</div>
                                                <div class="card_btn submit" v-if="item.status === 0">开始做题</div>
                                                <div class="card_btn process" v-else-if="item.status === 1">继续做题</div>
                                                <div class="card_btn analysis" v-else-if="item.status === 2">查看分析</div>
                                                <div class="card_btn analysis" v-else-if="item.status === 3">查看分析</div>
                                            </div>
                                        </div>

                                    </div>
                                </van-list>
                            </van-pull-refresh>
                        </div>

                    </van-tab>
                </van-tabs>
            </div>
        </van-sticky>


        <transition name="van-slide-right">

            <div class="grade_list" v-show="showGradeList" :style="{zIndex:$A.getMaxZIndex()}">
                <div class="grade_text inline" :class="{active:showGradeList}" @click="showGradeList = !showGradeList">
                    {{gradeSelected}}
                </div>
                <div class="list_title">请选择年级</div>
                <div class="grade_group" v-for="(group,index) in gradeList" :key="index">
                    <div class="group_title">{{group.group}}</div>
                    <div class="group_list">
                        <div class="grade_item"
                             :class="{active:grade.gradeId === gradeId}"
                             @click="gradeChange(grade)"
                             v-for="(grade,key) in group.list" :key="key">
                            {{grade.name}}
                        </div>
                    </div>
                </div>

            </div>
        </transition>

    </div>
</template>

<script>
    import {mapGetters} from "vuex";
    import TestApi from "../../api/TestApi";

    export default {
        name: "Test",
        data() {
            return {

                testData : {
                    params : {
                        gradeId : 0,
                        subjectId: 0,
                        //页码
                        start: 0,
                        //每页条数
                        length: 10,
                        examType: 'ordinary',
                        completionStatus: "incomplete",
                    },
                },
                /*配置项*/
                //页面高度
                pageH: 0,
                //吸顶顶部偏移高度
                topH: 0,
                //列表高度，用于计算吸顶距离
                listH: 0,
                //当前所选年级
                gradeSelected: '全年级',
                //年级id
                gradeId: 0,
                //当前所选科目
                subjectSelected: '',
                //科目id
                subjectId: 0,
                //科目索引
                subjectIndex: 0,
                //显示年级选择框
                showGradeList: false,
                // //年级列表
                // gradeList: [],
                // //科目列表
                // subjectList: [],
                //类型列表
                typeList: [
                    {title: '待交测评', tag: 'incomplete', subjectId:0, data: [],page:0,total:0,loading: false, finished: false, refreshing: false},
                    {title: '已交作测评', tag: 'complete', subjectId:0, data:[],page:0,total:0,loading: false, finished: false, refreshing: false },
                    {title: '往期测评', tag: 'history', subjectId:0, data:[],page:0,total:0,loading: false, finished: false, refreshing: false }
                ],
                //类型索引
                typeIndex: 0,
                //是否吸顶
                isFixed: false,

                //测试列表
                testList: {complete: [], incomplete: [], history: []},
                //页码
                pageIndex: 0,
                //每页条数
                pageSize: 10,
                //是否下拉刷新
                isLoading: false,

                //底部加载距离,
                scrollBottom: 50,
                //下拉加载防抖对象
                debounceObj: null,
                //吸顶防抖
                stickyDebounce: null,
            }
        },
        mounted() {
            this.debounceObj = this.$A.debounce(this.debounceHandle, 1000);
            // this.stickyDebounce = this.$A.debounce(this.fixedHandle, 10);
            this.pageInit();
            this.$nextTick(() => {
                setTimeout(() => {
                    document.querySelector('.van-pull-refresh').addEventListener('scroll', this.handleScroll, true)//监听函数
                }, 100)
            })
        },
        // activated() {
            // window.addEventListener('resize', this.pageInit);


        // },
        computed: {
            ...mapGetters({
                //科目列表
                subjectInfo: 'common/getSubjectList',
                //年级列表
                gradeInfo: 'common/getGradeList',
            }),

            //计算年级列表
            gradeList() {
                return [
                    {grade: '', list: [{name: '全年级', gradeId: 0}]},
                    {
                        group: '小学阶段',
                        list: [
                            {
                                name: '一年级',
                                gradeId: 1,
                            },
                            {
                                name: '二年级',
                                gradeId: 2,
                            },
                            {
                                name: '三年级',
                                gradeId: 3,
                            },
                            {
                                name: '四年级',
                                gradeId: 4,
                            },
                            {
                                name: '五年级',
                                gradeId: 5,
                            },
                            {
                                name: '六年级',
                                gradeId: 6,
                            },
                        ]
                    },
                    {
                        group: '初中阶段',
                        list: [
                            {
                                name: '初一',
                                gradeId: 7,
                            },
                            {
                                name: '初二',
                                gradeId: 8,
                            },
                            {
                                name: '初三',
                                gradeId: 9,
                            },
                        ]
                    },
                ]
            },

            // //计算科目列表
            subjectList() {
                let temp = [];
                temp.push({
                    name: '全部',
                    subjectId: 0,
                })
                let subjectFilter = [];
                // console.log('this.gradeId', this.gradeId);
                switch (this.gradeId) {
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                    case 6:
                        subjectFilter = ['语文','数学','英语'];
                        break;
                    case 7:
                        subjectFilter = ['语文','数学','英语','政治','历史','地理','生物'];
                        break;
                    case 8:
                        subjectFilter = ['语文','数学','英语','物理','政治','历史','地理','生物'];
                        break;
                    case 9:
                        subjectFilter = ['语文','数学','英语','政治','物理','化学','历史'];
                        break;

                }
                // console.log('subjectFilter', subjectFilter);
                this.subjectInfo.forEach(item => {
                    // console.log('item', item);
                    if(!this.gradeId){
                        temp.push({
                            name: item.name,
                            subjectId: parseInt(item.id)
                        })
                    }else{
                        if(subjectFilter.indexOf(item.name) !== -1){
                            temp.push({
                                name: item.name,
                                subjectId: parseInt(item.id)
                            })
                        }
                    }

                })
                return temp;
            },
            /*listResult() {
                let res = [];
                // let res = this.testList.filter(item=>this.subjectId == item.subjectids[0] && this.gradeId == item.gradeid);
                if (this.gradeId && this.subjectId) {
                    res = this.testList[this.typeList[this.typeIndex].tag].filter(item => this.subjectId == item.subjectids[0] && this.gradeId == item.gradeid);
                } else if (this.gradeId && !this.subjectId) {
                    res = this.testList[this.typeList[this.typeIndex].tag].filter(item => this.gradeId == item.gradeid);
                } else if (!this.gradeId && this.subjectId) {
                    res = this.testList[this.typeList[this.typeIndex].tag].filter(item => this.subjectId == item.subjectids[0]);
                } else {
                    res = this.testList[this.typeList[this.typeIndex].tag];
                }
                console.log('res', res);
                return res;
            }*/
        },

        watch: {},
        methods: {
            //卡片点击
            cardItemClick(obj) {
                let testId = obj.testids[0];
                let studentId = obj.studentid;

                //记录科目id用于做题界面反馈
                // console.log('current studentId', studentId);
                let currentSubjectId = obj.subjectids[0];
                this.$L.myLocal('__CURRENT_SUBJECT_ID__', currentSubjectId);

                //obj.status 考试状态： -1: 已结束（未做题），0: 未开始，1:正在做题，2:已完成（可查看基础分析），3: 已分析完成
                if (obj.status === 3) {//已经分析
                    this.$router.push({
                        name: 'studentTestPaper',
                        query: {testId: testId, studentId: studentId}
                    })
                }
                if (obj.status === 0 || obj.status === 1) {
                    // this.$router.push({path:'/Demo'});
                    this.$router.push({path: `/TestPage/${obj.testids[0]}/0`})
                } else if (obj.status === -1 || obj.status === 2) {
                    /*this.$router.push({
                        name: 'titleList',
                        query: {testId: testId, correctable: "corrected"}
                    })*/
                    this.$router.push({
                        name: 'Analysis',
                        query: {testId: testId, studentId: studentId}
                    })
                } else if (obj.status === 3) {
                    this.$router.push({
                        name: 'Analysis',
                        query: {testId: testId, studentId: studentId}
                    })
                    /*this.$router.push({
                        name: 'studentTestPaper',
                        query: {testId: testId, studentId: studentId}
                    })*/
                } else {
                    this.$notify('待老师分析完成才能查看题目')
                }
                // console.log('TODO:判断按钮是开始考试还是继续考试',obj);
                //当考试状态不是可查看解析的时候
                // 根据对象中的testids的第一个元素id，去匹配localStorage中的总计时器，找到对应的id，如果存在，则可以显示继续做题，否则就是开始做题
            },

            fixedHandle(isFixed) {
                console.log('isFixed____', isFixed);
                this.isFixed = isFixed;
            },
            //吸顶状态改变
            fixedChange(isFixed) {
                console.log('isFixed', isFixed);
                // if(isFixed){
                this.isFixed = isFixed;
                // }else{
                //     setTimeout(()=>{
                //         this.isFixed = isFixed;
                //     },300)
                // }

            },
            //科目id变更
            subjectChange(subjectId) {
                this.testData.params.subjectId = subjectId
                // this.testData.params.start = 0

                this.onRefresh();
            },
            //改变年级
            gradeChange(item) {
                this.gradeId = item.gradeId;
                this.subjectId = 0
                this.gradeSelected = item.name;
                this.showGradeList = false;
                this.testData.params.gradeId = item.gradeId
                this.testData.params.subjectId = this.subjectId
                // this.testData.params.start = 0
                //其他业务逻辑
                this.onRefresh();
            },

            //刷新
            onRefresh() {
                this.testData.params.start = 0
                this.typeList[this.typeIndex].page = 0
                this.typeList[this.typeIndex].total = 0
                this.typeList[this.typeIndex].finished = false
                this.typeList[this.typeIndex].loading = true
                this.typeList[this.typeIndex].data = []
                this.getTestList();
            },
            //获取试卷列表
            getTestList() {
                this.testData.params.start = this.typeList[this.typeIndex].page * this.testData.params.length;
                this.typeList[this.typeIndex].page++;
                this.typeList[this.typeIndex].subjectId = this.subjectId;
                TestApi.getExamList(this.testData.params).then(res => {
                    if (res.success) {
                        // this.testList = res.data;
                        this.typeList[this.typeIndex].loading = false
                        this.typeList[this.typeIndex].total = res.recordsTotal
                        if(this.typeList[this.typeIndex].page * this.testData.params.length >= this.typeList[this.typeIndex].total){
                            this.typeList[this.typeIndex].finished = true
                        }
                        if (this.typeList[this.typeIndex].data.length === 0){
                            this.typeList[this.typeIndex].data = res.data
                        }else {
                            res.data.forEach(item => {
                                this.typeList[this.typeIndex].data.push(item)
                            })
                        }

                        if (this.typeList[this.typeIndex].refreshing){
                            this.typeList[this.typeIndex].refreshing = false
                        }

                    }
                })
            },

            onTabs(val){
                if (this.subjectId !== this.typeList[this.typeIndex].subjectId){
                    this.onRefresh()
                }
                if (this.testData.params.completionStatus === this.typeList[this.typeIndex].tag){
                    return true
                }
                this.testData.params.completionStatus = this.typeList[val].tag
                this.typeList[this.typeIndex].page = 0
                this.typeList[this.typeIndex].total = 0
            },

            //获取科目颜色
            // getSubjectColor(subjectName) {
            //     let color = '';
            //     switch (subjectName) {
            //         case '语文':
            //             color = '#00A0E8'
            //             break;
            //         case '数学':
            //             color = '#FE4B4A'
            //             break;
            //         case '英语':
            //             color = '#FECB00'
            //             break;
            //         case '政治':
            //             color = '#00D06C'
            //             break;
            //         case '物理':
            //             color = '#F29B75'
            //             break;
            //         case '化学':
            //             color = '#EB68A2'
            //             break;
            //         case '生物':
            //             color = '#8FC21F'
            //             break;
            //         case '地理':
            //             color = '#12B5B1'
            //             break;
            //         case '历史':
            //             color = '#FEAD00'
            //             break;
            //
            //     }
            //     return color;
            // },


            //页面初始化
            pageInit() {

                this.isLoading = false;
                // this.getTestList('incomplete');
                // this.testData.params.

                this.onRefresh()
                setTimeout(() => {
                    this.$nextTick(() => {
                        let page = this.$refs.testPage.getBoundingClientRect();
                        let grade = this.$refs.gradeSelect.getBoundingClientRect();
                        let subject = this.$refs.subjectSelect.getBoundingClientRect();
                        // let swipe = this.$refs.swipeBox.getBoundingClientRect();
                        let task = this.$refs.taskType.getBoundingClientRect();

                        this.pageH = page.height;
                        this.topH = grade.height + subject.height;
                        this.listH = this.pageH - this.topH - task.height;
                        console.log('this.pageH', this.pageH, 'this.listH', this.listH, 'this.topH_____', this.topH);
                    })
                }, 300)

            },
            handleScroll() {
                let target = document.querySelector('.van-pull-refresh');
                // 变量scrollTop是滚动条滚动时，距离顶部的距离
                let scrollTop = target.scrollTop;
                //变量windowHeight是可视区的高度
                let windowHeight = target.clientHeight;
                //变量scrollHeight是滚动条的总高度
                let scrollHeight = target.scrollHeight;
                // console.log('scrollTop', scrollTop, 'windowHeight', windowHeight, 'scrollHeight', scrollHeight);

                let bottom = scrollHeight - windowHeight - scrollTop;
                // console.log('bottom', bottom);
                if (bottom < this.scrollBottom) {
                    console.log('下拉加载触发');
                    if (this.debounceObj) this.debounceObj();
                }

            },
            //防抖触发的处理函数
            debounceHandle() {
                console.log('触发防抖函数');
                //页码自增
                // this.pageIndex ++;
                // this.getTestList('防抖触发');

            }
        }

    }
</script>

<style lang="scss">
    .testPage {
        -webkit-overflow-scrolling: touch;

        /*::v-deep .van-sticky{*/
        /*    position: fixed;*/
        /*}*/
        .grade_select {
            background: #fff;
            height: 44px;
            /*position: relative;*/
            @extend .flex_row_center;
            justify-content: flex-start;
            padding: 0 12px;
        }


        .grade_text {
            font-size: 16px;
            position: relative;

            &.inline {
                display: inline-block;
                height: 44px;
                line-height: 44px;
            }

            &:after {
                content: '';
                position: absolute;
                width: 0;
                height: 0;
                border: 5px solid transparent;
                border-top-color: $defaultColor;
                top: 50%;
                right: -16px;
                transition: all .3s ease;
                transform: translateY(-10%);
            }

            &.active {
                &:after {
                    transform: rotate(-180deg) translateY(60%);
                    transform-origin: center;
                }
            }
        }

        .grade_list {
            position: fixed;
            padding: 0 12px;
            text-align: left;
            top: 0px;
            width: calc(100% - 24px);
            bottom: 0;
            left: 0;
            z-index: 99;
            background: #fff;
            transition: all .3s ease;

            .list_title {
                text-align: left;
                color: $defaultColor;
                font-size: 16px;
                font-weight: bold;
            }

            .grade_group {
                margin: 12px 0;

                .group_title {
                    text-align: left;
                }

                .group_list {
                    text-align: left;

                    .grade_item {
                        text-align: center;
                        display: inline-block;
                        flex-direction: row;
                        align-items: center;
                        justify-content: center;
                        width: 28%;
                        height: 40px;
                        line-height: 40px;
                        background: rgba(238, 238, 238, 1);
                        border-radius: 5px;
                        margin-right: 5%;
                        margin-bottom: 16px;

                        &.noMargin {
                            margin-right: 0;
                        }

                        &.active {
                            background: #ccc;
                        }
                    }
                }
            }
        }

        .subject_select {
            border-bottom: 1px solid rgba(234, 234, 234, 1);
        }

        .subject_select, .task_type {
            .van-tab {
                font-size: 14px;
                line-height: 20px;
                color: rgba(153, 153, 153, 1);
            }

            .van-tabs__nav--complete {
                padding-left: 0;
                padding-right: 0;
            }

            .van-tabs__line {
                background-color: rgba(1, 159, 232, 1);
                height: 3px;
                width: 20px;
            }

            .van-tab--active {
                color: rgba(1, 159, 232, 1);
            }
        }

        .swipe_box {
            padding: 16px;


            .swipe_content {
                border-radius: 5px;

                .van-swipe-item {

                    color: #fff;
                    font-size: 20px;
                    line-height: 150px;
                    text-align: center;
                    background-color: #39a9ed;

                    &:nth-child(1) {
                        background-color: rgba(255, 211, 211, 1);
                    }

                    &:nth-child(2) {
                        background-color: rgba(255, 201, 105, 1);
                    }

                    &:nth-child(3) {
                        background-color: rgba(71, 226, 151, 1);
                    }
                }
            }
        }

        .task_list {
            padding-bottom: 12px;
            overflow: auto;

            .list_item {
                padding: 20px 20px 16px;
                box-shadow: 1px 2px 10px 0 rgba(0, 0, 0, 0.2);
                border-radius: 5px;
                margin: 16px;

                .item_top {
                    @extend .flex_row_center;
                    padding-bottom: 16px;
                    border-bottom: 1px solid rgba(238, 238, 238, 1);

                    .subject_icon {
                        @extend .flex_row_center;
                        width: 40px;
                        height: 40px;
                        border-radius: 5px;
                        background: #00A0E8;
                        font-size: 16px;
                        font-weight: bold;
                        color: #fff;
                        margin-right: 12px;

                    }

                    .task_info {
                        flex: 1;
                        margin-right: 12px;
                        @extend .flex_column;
                        align-items: flex-start;

                        .task_title {
                            font-size: 16px;
                            font-weight: bold;
                            color: $defaultColor;
                            width: 100%;
                            text-align: left;
                        }

                        .task_info_box {
                            @extend .flex_row_center;
                            width: 100%;
                            justify-content: flex-start;
                            font-size: 12px;

                            .info_item {
                                color: rgba(102, 102, 102, 1);
                                flex: 1;
                                margin-right: 12px;
                                @extend .flex_row_center;
                                justify-content: flex-start;

                                &:last-child {
                                    justify-content: flex-end;
                                }

                                &.result {
                                    color: rgba(255, 85, 101, 1);
                                }

                                &.process {
                                    color: #FFA201;
                                }

                                &.start {
                                    color: #019fe8;
                                }

                                &.undo {
                                    color: rgba(102, 102, 102, 1);
                                }

                                &.self {
                                    color: #FFA201;
                                }

                                &.undo {
                                    color: #333;
                                }
                            }

                        }
                    }

                    .item_arrow {
                        @extend .flex_row_center;

                        img {
                            width: 20px;
                            height: 20px;
                            transform: rotate(-180deg);
                        }
                    }
                }

                .item_bottom {
                    @extend .flex_row_center;
                    font-size: 12px;
                    color: rgba(102, 102, 102, 1);
                    padding-top: 16px;

                    .item_grade {
                        min-width: 80px;
                        text-align: left;
                    }

                    .item_date {

                    }

                    .item_btn {
                        flex: 1;
                        @extend .flex_row_center;
                        justify-content: flex-end;

                        .card_btn {
                            @extend .flex_row_center;
                            font-size: 14px;
                            color: #fff;
                            height: 30px;
                            width: 80px;
                            transition: all .3s ease;
                            border-radius: 15px;


                            &.undo {
                                background: transparent;
                                color: #666;
                            }

                            &.detail {

                                background: linear-gradient(-21deg, #019FE8 0%, #6AD0FF 100%);

                                &:active {
                                    background: linear-gradient(-21deg, #019FE8 100%, #6AD0FF 0%);
                                }
                            }

                            &.submit {
                                background: linear-gradient(-21deg, rgba(255, 152, 144, 1) 100%, rgba(255, 101, 92, 1) 0%);

                                &:active {
                                    background: linear-gradient(-21deg, rgba(255, 152, 144, 1) 0%, rgba(255, 101, 92, 1) 100%);
                                }
                            }

                            &.process {

                                background: linear-gradient(-21deg, rgba(255, 163, 0, 1) 0%, rgba(255, 201, 105, 1) 100%);

                                &:active {
                                    background: linear-gradient(-21deg, rgba(255, 163, 0, 1) 100%, rgba(255, 201, 105, 1) 0%);
                                }
                            }

                            &.analysis {
                                background: linear-gradient(-21deg, rgba(71, 226, 151, 1) 100%, rgba(1, 194, 101, 1) 0%);

                                &:active {
                                    background: linear-gradient(-21deg, rgba(71, 226, 151, 1) 0%, rgba(1, 194, 101, 1) 100%);
                                }
                            }


                        }
                    }
                }
            }
        }

        /*ipad*/
        @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
            .grade_select {
                height: 88px;
                padding: 0 24px;


            }
            .grade_text {
                font-size: 32px;

                &.inline {
                    height: 88px;
                    line-height: 88px;
                }

                &:after {
                    border-width: 10px;
                    right: -32px;
                }
            }
            .grade_list {
                /*top: 88px;*/
                padding: 0 24px;
                width: calc(100% - 48px);

                .list_title {
                    font-size: 32px;
                }

                .grade_group {
                    margin: 24px 0;

                    .group_list {
                        .grade_item {
                            height: 80px;
                            line-height: 80px;
                            width: 180px;
                            border-radius: 10px;
                            margin-bottom: 32px;
                        }
                    }
                }
            }

            .subject_select, .task_type {
                .van-tab {
                    font-size: 28px;
                    line-height: 40px;
                }

                .van-tabs__line {
                    height: 6px;
                    width: 40px;
                }

                .van-tabs__wrap--scrollable .van-tab {
                    padding: 0 24px;
                }
            }

            .swipe_box {
                padding: 32px;

                .swipe_content {
                    border-radius: 10px;

                    .van-swipe-item {
                        font-size: 40px;
                        line-height: 300px;
                    }
                }
            }

            .task_list {
                padding-bottom: 24px;

                .list_item {
                    padding: 40px 40px 32px;
                    box-shadow: 2px 4px 20px 0 rgba(0, 0, 0, 0.2);
                    border-radius: 10px;
                    margin: 32px;

                    .item_top {
                        padding-bottom: 32px;
                        border-bottom: 2px solid rgba(238, 238, 238, 1);

                        .subject_icon {
                            width: 80px;
                            height: 80px;
                            border-radius: 10px;
                            font-size: 32px;
                            margin-right: 24px;

                        }

                        .task_info {
                            margin-right: 24px;

                            .task_title {
                                font-size: 32px;
                            }

                            .task_info_box {
                                font-size: 24px;

                                .info_item {
                                    margin-right: 24px;
                                }

                            }
                        }

                        .item_arrow {
                            img {
                                width: 40px;
                                height: 40px;
                            }
                        }
                    }

                    .item_bottom {
                        font-size: 24px;
                        padding-top: 32px;

                        .item_grade {
                            min-width: 160px;
                        }

                        .item_date {

                        }

                        .item_btn {
                            .card_btn {
                                font-size: 28px;
                                height: 60px;
                                width: 160px;
                                border-radius: 30px;
                            }
                        }
                    }
                }
            }
        }
    }

</style>
