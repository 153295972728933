import { post } from "./http";

const TestApi = {
    //获取题目列表
    getExamList: (params) => post('/index.php?r=mobileapp/onLineTestExam/getOnlineTestExamList', params),

    //考试题目 - 获取考试题目
    getExamQuestion:(params)=>post('/index.php?r=mobileapp/OnLineTestExam/GetOnlineTestExamQuestions',params),

    //考试题目 - 提交答案
    postAnswer: (params) => post('/index.php?r=mobileapp/OnLineTestExam/PostQuestionAnswer', params),
}

export default TestApi;